<template>
	<div class="solution">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-lg-3 mb-5">
					<SideBar
						id="tmtSolutionSideBar"
						:btns="btns"
						:value="contentId"
					></SideBar>
				</div>
				<div class="col-12 col-lg-9">
					<div class="container-fluid" v-if="contentId === 'winds-hvac'">
						<div class="row">
							<div class="col">
								<h1>
									<span class="primary text-bold">
										中央空調數據神經系統
									</span>
									<span class="secondary text-bold">
										WINDS.HVAC
									</span>
								</h1>
							</div>
						</div>
						<div class="row mb-3">
							<div class="col">
								<h3>
									<span class="text-bold">
										從空氣側到冰水側的全系統控制
									</span>
									<br />
									<span class="text-bold">
										專家級大數據指標分析監視管理
									</span>
									<br />
									<span class="text-bold">
										工業無線神經網路全覆蓋易擴充
									</span>
									<br />
								</h3>
							</div>
						</div>
						<div class="row">
							<div class="col-12 mt-2">
								<h5 class="text-bold text-highlight">專家分析大數據</h5>
								<ul>
									<li>
										<span class="text-bold">
											全系統控制平台：
										</span>
										<span>
											需求空氣側與供給冰水側全系統控制，六大控制系統設備管理分析
										</span>
									</li>
									<li>
										<span class="text-bold">
											專家級數據分析：
										</span>
										<span>
											中央空調專用指標運算分析，不同單位趨勢線波型重疊敏感度分析
										</span>
									</li>
									<li>
										<span class="text-bold">
											大數據管理平台：
										</span>
										<span>
											專家型指標大數據倉儲管理，全系統與六大系統監控制儀表板管理
										</span>
									</li>
									<li>
										<span class="text-bold">
											跨系統數據整合：
										</span>
										<span>
											支援Modbus/TCP與 API Service跨SCADA監控與IT系統數據交換
										</span>
									</li>
								</ul>
							</div>
							<div class="col-12 mt-2">
								<h5 class="text-bold text-highlight">系統擴充不受限</h5>
								<ul>
									<li>
										<span class="text-bold">
											擴充種類不受限：
										</span>
										<span>
											支援Modbus與類比型之各種空調設備與環境感測
										</span>
									</li>
									<li>
										<span class="text-bold">
											擴充數量不受限：
										</span>
										<span>
											動態神經擴充技術，在線節點即時擴充系統不停機
										</span>
									</li>
									<li>
										<span class="text-bold">
											數據傳送不受限：
										</span>
										<span>
											無需PLC與DDC有線訊號工程，縮短系統建置時間
										</span>
									</li>
								</ul>
							</div>
							<div class="col-12 mt-2">
								<h5 class="text-bold text-highlight">工業級無線網路</h5>
								<ul>
									<li>
										<span class="text-bold">
											工業級無線技術：
										</span>
										<span>
											工業級2.4G Mesh無線技術，穩定安全專用頻道，消除干擾風險
										</span>
									</li>
									<li>
										<span class="text-bold">
											自動修復高穩定：
										</span>
										<span>
											神經網路自動偵測修復技術，維持網路高可靠度，降低系統風險
										</span>
									</li>
									<li>
										<span class="text-bold">
											安全加密高防護：
										</span>
										<span>
											ASE/SSL國際標準加密技術確保數據傳輸安全，確保資安風險
										</span>
									</li>
								</ul>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/solution_01_01.png" />
							</div>
						</div>
						<div class="row mt-5">
							<div class="col">
								<h2 class="text-bold">數據神經系統技術架構</h2>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p class="text-highlight">
									中央空調數據神經系統」具有安全相容於各種設備，與彈性擴充的系統特性
								</p>
								<p>
									在聯合報案例中納入神經感知系統的設備與裝置有數十種，如果考慮未來的擴充與廠牌型號更新，則可能面臨上百種的設備管理問題
								</p>
								<p>
									「神經感知系統」與「設備通訊模組」技術建立一個標準模組化的設備管理平台，克服各廠牌設備連接系統障礙，讓設備的異常狀況可以快速排除，加快設備修復上線的速度
								</p>
								<p>
									「無線神經網路」以特殊硬體編碼技術(非IP層資料傳輸)，除讓數據傳輸更輕盈快速，可以彈性擴充至上千個節點，也不影響企業Wi-Fi網路，加上國際AES
									128位元傳輸加密技術，防護系統數據傳輸更安全
								</p>
								<p>
									為了與不同的監控系統(SCADA)互補，「神經數據電腦」提供標準工業(Modbus/TCP)傳輸協定的資料交換功能，也同時提供標準API
									Service 跨異質系統與資料庫數據整合更方便
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/solution_01_02.png" />
							</div>
						</div>
					</div>

					<div class="container-fluid" v-if="contentId === 'aies-hvac'">
						<div class="row">
							<div class="col">
								<h1>
									<span class="primary text-bold">
										微氣候能量AI執行系統
									</span>
									<span class="secondary text-bold">
										AIES.HVAC
									</span>
								</h1>
							</div>
						</div>
						<div class="row mb-3">
							<div class="col">
								<h3>
									<span class="text-bold">
										需求側能量決定供給側能量
									</span>
									<br />
									<span class="text-bold">
										人工智慧控制六大能量均衡
									</span>
									<br />
								</h3>
							</div>
						</div>
						<div class="row">
							<div class="col-12 mt-2">
								<h5 class="text-bold text-highlight">需求側能量控制</h5>
								<ul>
									<li>
										<span class="text-bold">
											設備側負載需量控制：
										</span>
										<span>
											FCU、AHU、分離式冷氣等設備之需量控制
										</span>
									</li>
									<li>
										<span class="text-bold">
											情境式區域需量控制：
										</span>
										<span>
											情境式能量區域設定，設備群組管理與控制
										</span>
									</li>
									<li>
										<span class="text-bold">
											需求側負載總量控制：
										</span>
										<span>
											需求側全設備與區域之加總負載預測與控制
										</span>
									</li>
								</ul>
							</div>
							<div class="col-12 mt-2">
								<h5 class="text-bold text-highlight">供給側能量控制</h5>
								<ul>
									<li>
										<span class="text-bold">
											冰水能量平衡控制：
										</span>
										<span>
											動態調控一次側與二次側之水量平衡控制
										</span>
									</li>
									<li>
										<span class="text-bold">
											主機供給能量控制：
										</span>
										<span>
											動態調控主機側冰水出水溫度與停機參數
										</span>
									</li>
									<li>
										<span class="text-bold">
											冷卻散熱能量控制：
										</span>
										<span>
											依供給能量調控最佳水塔散熱與冷卻水量
										</span>
									</li>
								</ul>
							</div>
							<div class="col-12 mt-2">
								<h5 class="text-bold text-highlight">AI策略執行管理</h5>
								<ul>
									<li>
										<span class="text-bold">
											策略學習中心：
										</span>
										<span>
											循環分析大數據倉儲之能量指標，學習優化控制策略
										</span>
									</li>
									<li>
										<span class="text-bold">
											能量分析中心：
										</span>
										<span>
											需求側與供給側之能量均衡分析連動AI系統調整參數
										</span>
									</li>
									<li>
										<span class="text-bold">
											控制執行中心：
										</span>
										<span>
											接收AI策略中心之命令參數由無線神經系統執行控制
										</span>
									</li>
								</ul>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/solution_02_01.png" />
							</div>
						</div>
						<div class="row mt-5">
							<div class="col">
								<h2 class="text-bold">AI執行系統架構</h2>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									「微氣候能量AI控制系統」將負責不同微氣候能量的設備建立一個協同運作的神經系統，接受「AI控制中心」的命令指揮，讓系統隨時保持動態平衡
								</p>
								<p class="text-highlight">
									當微氣候系統的六種能量彼此保持最適均衡時，室內微氣候會更穩定舒適，系統的效率最高，總能源消耗最低！
								</p>
								<p>
									「微氣候能量模型」扮演「AI控制中心」的能量專家角色，「AI控制中心」依照能量模型的分析產生最佳控制命令，再透過「無線神經系統」建立相關設備的神經系統，執行參數命令與能量監測
								</p>
								<p>
									「AI控制命令」的執行結果透過「能量監測系統」匯集到「能量大數據中心」提供給「微氣候能量模型」與「AI控制中心」不斷進行「AI學習」使整體系統不斷的進化，增強AI的分析與控制能力
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/solution_02_02.png" />
							</div>
						</div>
						<div class="row mt-5">
							<div class="col">
								<h2 class="text-bold">供給側能量AI控制</h2>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									<span class="text-highlight">
										一般中央空調監控系統(SCADA)為供給側導向，並以各別設備監控為主
									</span>
									<span>
										，無法掌握需求側能量，與設備協同能量控制，造成系統能量浪費與失衡
									</span>
								</p>
								<p>
									<span class="text-highlight">
										「微氣候能量AI控制系統」特色由需求能量決定供給能量
									</span>
									<span>
										，補足即有一般監控系統(SCADA)
										無法處理能量控制的缺點，徹底解決能量浪費問題
									</span>
								</p>
								<p>
									因為以需求側能量導向為目標，AI控制系統會依「需求側能量分析數據」得出需求能量目標，「AI控制中心」再以需求側能量決定控制最適「冰水側供給能量」，並協同控制其它三種能量保持均衡
								</p>
								<p class="text-highlight">
									當供給側的能量達到最適均衡時，相對供給側系統的效率達到最好，總能量消耗也會最低！
								</p>
								<p>
									聯合報案例證明，在高效率的主機設備搭配AI控制系統後，發揮更明顯的節能效果
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/solution_02_03.png" />
							</div>
						</div>
						<div class="row mt-5">
							<div class="col">
								<h2 class="text-bold">需求側能量AI控制</h2>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p class="text-highlight">
									先控制需求側能量不浪費，才能降低供給側能量浪費，所以「需求側能量AI控制」扮演能量控制的核心功能，與維持微氣候穩定的核心功能
								</p>
								<p>
									「需求側能量AI控制」第一步是要升級即有的584台風機(FCU)設備具有AI需量控制能力，讓需求負載達可精確控制
								</p>
								<p>
									當設備側能被AI精準控制時，才能依情境區域的能量特性，控制每一設備與區域的需量變化，也同時掌控「空氣側總負載需量」的變化
								</p>
								<p>
									<span>
										最後以AI系統控制水量變頻設備，調整「冰水側換熱需量」的能量，使需求側能量達到平衡，
									</span>
									<span class="text-highlight">
										並將「空氣負載監測」的結果，轉換為「需求側能量數據」成為供給側能量之目標
									</span>
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/solution_02_04.png" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SideBar from '@/components/SideBar.vue';

export default {
	name: 'About',
	components: {
		SideBar,
	},
	data() {
		return {
			contentId: '',
			btns: [
				{
					id: 'winds-hvac',
					text: '中央空調數據神經系統',
					link: '/solution/winds-hvac',
				},
				{
					id: 'aies-hvac',
					text: '微氣候能量AI執行系統',
					link: '/solution/aies-hvac',
				},
			],
		};
	},
	computed: {
		contentIds() {
			return this.btns.map((btn) => btn.id);
		},
	},
	methods: {
		ContentIdReload(target) {
			let result = this.contentIds[0];
			if (this.contentIds.includes(target)) {
				result = target;
			}
			this.contentId = result;
		},
	},
	watch: {
		$route() {
			this.ContentIdReload(this.$route.params.name_id);
		},
	},
	created() {
		this.ContentIdReload(this.$route.params.name_id);
	},
};
</script>

<style scoped>
h1 .primary {
	color: rgb(45, 177, 96);
	font-size: 1.75rem;
}
h1 .secondary {
	color: rgb(234, 90, 49);
	margin-left: 1rem;
	font-size: 1.75rem;
}
h2 {
	color: rgb(192, 0, 0);
	font-size: 1.5rem;
}
h3 {
	color: rgb(234, 90, 49);
	font-size: 1.25rem;
}
.solution {
	font-size: 1.25rem;
}
.text-bold {
	font-weight: bold;
}
.text-highlight {
	color: rgb(52, 172, 139);
}
</style>
